import React from 'react'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputMask from 'react-input-mask';
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'

const FieldInput = ({ input, meta, type, placeholder, min, max, disabled }) => {
    return (
        <FormControl
            type={type}
            placeholder={placeholder}
            min={min}
            max={max}
            value={input.value}
            onChange={input.onChange} />
    )
}

export const FieldSelect = ({ input, meta, options }) => {
    return (
        <Form.Control
            as="select"
            {...input}
            onChange={input.onChange}
            value={input.value}
        >
            {
                options.map(function(v, i){
                    return <option key={i} value={v}>{v}</option>
                })
            }
        </Form.Control>
    )
}

export const FieldTextarea = ({ input, meta, type, placeholder, min, max, disabled }) => {
    return (
        <FormControl
            as="textarea"
            placeholder={placeholder}
            rows="5"
            value={input.value}
            onChange={input.onChange} />
    )
}

export const MaskedInput = ({ input, meta, type, placeholder, min, max, disabled, mask }) => {
    return (
        <InputMask 
            mask={mask} 
            type={type}
            placeholder={placeholder}
            min={min}
            max={max}
            value={input.value}
            onChange={input.onChange}
        >
            {(inputProps) => <FormControl {...inputProps} />}
        </InputMask>
    )
}

export const GroupInput = ({ input, meta, type, placeholder, min, max, disabled, btn, handle }) => {
    return (
        <InputGroup>
            <FormControl
                type={type}
                placeholder={placeholder}
                min={min}
                max={max}
                value={input.value}
                onChange={input.onChange} />
            <InputGroup.Append>
                <Button value={input.value} onClick={input.onBlur} variant="outline-secondary">{btn}</Button>
            </InputGroup.Append>
        </InputGroup>
    )
}

export const GroupMaskedInput = ({ input, meta, type, placeholder, min, max, disabled, btn, handle, mask }) => {
    return (
        <InputGroup>
            <InputMask 
                mask={mask} 
                type={type}
                placeholder={placeholder}
                value={input.value}
                onChange={input.onChange}
            >
                {(inputProps) => <FormControl {...inputProps} />}
            </InputMask>

            <InputGroup.Append>
                <Button value={input.value} onClick={input.onBlur} variant="outline-secondary">{btn}</Button>
            </InputGroup.Append>
        </InputGroup>
    )
}

export const CustomCheckbox = ({ input, meta, type, id, className }) => {
    return (
        <input type="checkbox" {...input} id={id} className={className} />
    )
}

export default FieldInput