import axios from 'axios'

export function getmeetings(token){
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}meetings`, config)
			.then(resp => {
				console.log(resp)
				if(resp.status == 200){
					dispatch([
						{ type: 'MEETINGS_FETCH', payload: resp.data.payload }
					])
				}
			})
	}
}

export function getmeetingsBB(token){
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}meetingsbb`, config)
			.then(resp => {
				console.log(resp)
				if(resp.status == 200){
					dispatch([
						{ type: 'MEETINGS_BB_FETCH', payload: resp.data.payload }
					])
				}
			})
	}
}

export function getmeeting(token, meeting) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}meetings/${meeting}`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{ type: 'MEETING_FETCH', payload: resp.data.meeting }
					])
				}
			})
	}
}

export function getmeetingBB(token, meeting) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}meetingsbb/${meeting}`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{ type: 'MEETING_BB_FETCH', payload: resp.data.meeting }
					])
				}
			})
	}
}