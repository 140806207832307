import React from 'react'

import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Footer from '../common/footer'
import Menu from '../common/menu'

import FieldInput from '../../helpers/inputs.js'

import { update_profile, changepwd } from '../../helpers/actions/login'
import { loading } from '../../helpers/actions/content'

function fileUpload(e, props){
	const $file = e.target.files[0]
	const { update_profile, loading } = props

	loading()
	update_profile($file, props.clienteData.slug, props.userToken)
}

function profile(props){
	const breadcrumb = [props.clienteData.nome, 'Painel', 'Meus Dados']

	const {handleSubmit, changepwd} = props

	return(
		<div>
			<Header titulo="Meus Dados" icone="dripicons-user" breadcrumb={breadcrumb} />

			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    <div className="row">
	                    	<div className="col-12">
	                    		<div className="row">
						            <div className="col-12 col-xl-8">
                						<div className="card">
                    						<div className="card-body">
                        						<h4 className="mt-0 header-title">Meus Dados</h4>
                        						<h5>Nome</h5>
							                    <p>{ props.userData.name }</p>
							                    <hr />

							                    {(props.userData.email != props.userData.matricula) ? (
							                    	<>
							                    	<h5>E-mail</h5>
								                    <p>{ props.userData.email }</p>
								                    <hr />
								                    </>
							                    ) : (
							                    	<>
							                    	</>
							                    )}

							                    <h5>Matrícula</h5>
							                    <p>{ props.userData.matricula }</p>
							                    <hr />

							                    <h5>Turma</h5>
							                    <p>{ props.userData.turma } </p>
							                    <hr />

							                    <h5>Data de Nascimento</h5>
							                    <p>{ props.userData.nascimento }</p>
							                    <hr />

							                    <h5>Gênero</h5>
							                    <p>{ props.userData.genero }</p>
							                    <hr />

							                    <h5>Telefones</h5>
							                    {(JSON.parse(props.userData.telefones).length > 0) ? (
							                    	<ul>
							                    	{(JSON.parse(props.userData.telefones).map((telefone, index) => {
							                    		return (
							                    			<li key={index}><strong>{ telefone.tipo }:</strong> { telefone.telefone }</li>
							                    		)
	            									}))}
							                    	</ul>
							                    ) : (
							                    	<>
							                    	-
							                    	</>
							                    )}
							                    <hr />

							                    <h5>Endereços</h5>
							                    {(JSON.parse(props.userData.endereco).length > 0) ? (
							                    	<ul>
							                    	{(JSON.parse(props.userData.endereco).map((end, index) => {
							                    		return (
							                    			<li key={index}>{ end }</li>
							                    		)
	            									}))}
							                    	</ul>
							                    ) : (
							                    	<>
							                    	-
							                    	</>
							                    )}
							                    <hr />
                        					</div>
                        				</div>	
                        			</div>
                        			<div className="col-12 col-xl-4 mb-4">
						                <div className="card">
						                    <div className="card-body">
												<div className="form-group">
													{(props.loadingData) ? (
														<>
								                    	<div className="loader">Loading...</div>
								                    	</>
													) : (
														<>
															{(props.userData.avatar != '') ? (
																<>
																<img src={props.userData.avatarFiltered} style={{width:'200px', height:'auto', clear:'both', display:'block', padding:'2px', border:'1px solid #ddd', marginBottom:'10px', marginLeft: 'auto', marginRight: 'auto' }} />
																</>
															) : (
																<>
																</>
															)}
															<input 
																type="file" 
																name="avatar" accept="image/png, image/jpeg, image/heic, image/heif"
																onChange={(e) => {fileUpload(e, props)}} />	
														</>
													)}

													<form className="mt-4 d-none" onSubmit={handleSubmit(value => changepwd(value, props.userToken))}>
						    							<div className="form-group">
						                                    <label htmlFor="password">Senha</label>
						                                    <div className="input-group mb-3">
						                                        <div className="input-group-prepend">
						                                            <span className="input-group-text" id="basic-addon9"><i className="mdi mdi-key font-16"></i></span>
						                                        </div>
						                                        <Field
																	name="password"
																	placeholder="Nova Senha"
																	type="password"
																	component={ FieldInput }
																/>
						                                    </div>                                    
						                                </div>

						                                <div className="form-group">
						                                    <label htmlFor="passwordconfirmation">Confirmação de Senha</label>
						                                    <div className="input-group mb-3">
						                                        <div className="input-group-prepend">
						                                            <span className="input-group-text" id="basic-addon9"><i className="mdi mdi-key font-16"></i></span>
						                                        </div>
						                                        <Field
																	name="passwordconfirmation"
																	placeholder="Confirmar Senha"
																	type="password"
																	component={ FieldInput }
																/>
						                                    </div>                                    
						                                </div>
						    
						                                <div className="form-group mb-0 row">
						                                    <div className="col-12 mt-2">
						                                        <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Enivar <i className="fas fa-sign-in-alt ml-1"></i></button>
						                                    </div>
						                                </div>  

						                                {props.submitting ?
						                                	<div>Aguarde</div>
						                                :
						                                	<div dangerouslySetInnerHTML={{ __html: props.errorUser }}></div>
						                                }
						                            </form>
												</div>
												
					                    	</div>
					                	</div>
						            </div>
						        </div>
	                    	</div>
	                    </div>
	                </div>

	                <Footer cliente={props.clienteData.nome} sistema={props.clienteData.sistema} />
	            </div>
	        </div>
		</div>
	)
}

profile = reduxForm({form: 'profileForm'})(profile)
const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	errorUser: state.profile.errorUser,
	loadingData: state.main.loading
})

const mapDispatchToProps = dispatch => bindActionCreators({
	update_profile,
	loading,
	changepwd
}, dispatch)

const conProfile = connect(mapStateToProps, mapDispatchToProps)(profile)
export default conProfile