import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Footer from '../common/footer'
import Menu from '../common/menu'

import { getaulas } from '../../helpers/actions/content'

function aulas(props){
	const breadcrumb = [props.clienteData.nome, 'Trilhas de Aprendizagem', 'Lista de Trilhas de Aprendizagem']

	const {getaulas} = props
	setTimeout(function(){
		if(props.contentData.length == 0){
			let autor = (props.match.params['autor']) ? props.match.params['autor'] : 0;
			let material = (props.match.params['materia']) ? props.match.params['materia'] : 0;
			getaulas(props.userToken, autor, material)
		}
	}, 1500)

	const handleRender = (prop, other) => {
		console.log(prop)
	}

	const renderAulas = (conteudos) => {
		if(conteudos[0].length > 0){
			return (
				<>
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<div className="container">
									<div className="d-flex justify-content-md-center">
										<div className="px-4 pb-xs-4">
											<i className="mdi mdi-circle-slice-8 font-18 mr-1 text-primary"></i> A fazer
										</div>
										<div className="px-4">
											<i className="mdi mdi-circle-slice-8 font-18 mr-1 text-success"></i> Já feito
										</div>
										<div className="px-4 pt-xs-4">
											<i className="mdi mdi-circle-slice-8 font-18 mr-1 text-danger"></i> Não fez as tarefas
										</div>
									</div>	
								</div>
							</div>
						</div>
					</div>

					{conteudos[0].map((conteudo, index) => {
						return (
							<div className="col-lg-3" key={index}>
								<a href={`/trilhas-de-aprendizagem/${conteudo.id}`}>
									<div className="card profile-card">
										<div style={{ position: "relative", height: "110px", overflow: "hidden" }}>
											<img src={conteudo.background} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
										</div>
								        <div className="card-body pb-0 profile-card-user" style={{ position: "relative", zIndex: "2" }}>
								        	<div className="text-center">
								        		<img src={conteudo.avatar} className="rounded-circle thumb-xl img-thumbnail mb-2" />
								        		<p className="mb-0 font-12 text-muted">{conteudo.author}</p>
								            	<h5 className="mb-1">{conteudo.title}</h5>
								                <h6 className="text-center">{conteudo.materia}</h6>
								        		<h6 className="text-center">{conteudo.categoria}</h6>
								            </div>
								        </div>
								        <div className="card-body socials-data pb-0 px-0">
								        	<div className="row text-center">
								            	<div className="col-12">
								                	<span className={`font-14 btn btn-${conteudo.class} btn-block`}>Acessar</span>
								                </div>
								            </div>
								        </div>
								    </div>
								</a>
							</div>
						)
					})}
				</>
			)
		} else {
			return (
				<div className="col-12 mt-3">
					<p className="text-center">Não há dados para exibir</p>
				</div>
			)
		}
	}

	return(
		<>
			<Header titulo="Trilhas de Aprendizagem" icone="fas fa-book" breadcrumb={breadcrumb} />

			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    {(props.contentData.length > 0) ? (
	                    	<div className="row">
	                    		{renderAulas(props.contentData)}
	                    	</div>
	                    ) : (
	                    	<>
	                    	<div className="loader">Loading...</div>
	                    	</>
	                    )}
	                </div>

	                <Footer cliente={props.clienteData.nome} sistema={props.clienteData.sistema} />
	            </div>
	        </div>
		</>
	)
}

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	contentData: state.content.aulas
})

const mapDispatchToProps = dispatch => bindActionCreators({
	getaulas
}, dispatch)

const conAula = connect(mapStateToProps, mapDispatchToProps)(aulas)
export default conAula