import axios from 'axios'

export function getaula(token, aula) {
	const config = {
		headers: { 
			Authorization: `Bearer ${token}`,
			'Cache-Control': 'no-cache',
			'Pragma': 'no-cache'
		}
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}aula/${aula}`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{type: 'AULA_FETCH', payload: resp.data.aula},
						{type: 'COMMENTS_FETCH', payload: resp.data.comentarios},
						{type: 'UPLOADS_FETCH', payload: resp.data.uploads}
					])
				}
			})
	}
}

export function getconteudos(token) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}conteudo`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{type: 'CONTEUDO_FETCH', payload: resp.data.conteudos}
					])
				}
			})
	}	
}

export function getautores(token) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}aulas`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{type: 'AULAS_FETCH', payload: resp.data.aulas}
					])
				}
			})
	}	
}

export function getmaterias(token, autor = 0) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}aulas/${autor}`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{type: 'AULAS_FETCH', payload: resp.data.aulas}
					])
				}
			})
	}	
}

export function getaulas(token, autor = 0, materia = 0) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}aulas/${autor}/materia/${materia}`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{type: 'AULAS_FETCH', payload: resp.data.aulas}
					])
				}
			})
	}	
}

export function getaulaconteudo(id, token) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}conteudo/${id}`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{type: 'AULA_CONTEUDO_FETCH', payload: resp.data.conteudo}
					])
				}
			})
	}	
}

export function loading(){
	return dispatch => {
		dispatch([
			{ type: 'LOADING', payload: true }
		])
	}
}

export function comentar(values, token, aula, reply = 0, key = 0) {
	let formData = new FormData()

	for(var [i, value] of Object.entries(values)){
		if(i == ('comentario'+key)){
			formData.append('comentario', value)
		}
	}
	
	formData.append('aula', aula)
	formData.append('reply', reply)

	const config = {
		headers: { 
			Authorization: `Bearer ${token}`
		}
	}

	return dispatch => {
		dispatch([
			{ type: 'LOADING', payload: true }
		])

		axios.post(`${process.env.REACT_APP_URL}comment`, formData, config)
			.then(resp => {
				if(resp.error){
					dispatch([
						{ type: 'LOADING', payload: false },
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>'}
					])
				} else {
					dispatch([
						{ type: 'LOADING', payload: false },
						{type: 'COMMENTS_FETCH', payload: resp.data.comentarios}
					])
				}
			})
			.catch(e => {
				dispatch([
					{ type: 'LOADING', payload: false }
				])
			})
	}
}

export function upar(file, token, comentario, aula) {
	let formData = new FormData()

	formData.append('upload', file.raw)
	formData.append('aula', aula)
	formData.append('comentario', comentario)

	const config = {
		headers: { 
			Authorization: `Bearer ${token}`,
			"Content-Type": "multipart/form-data"
		}
	}

	return dispatch => {
		dispatch([
			{type: 'SEND_TEXT_CHANGE', payload: '<i class="fas fa-spinner fa-spin"></i>'}
		])

		axios.post(`${process.env.REACT_APP_URL}upload`, formData, config)
			.then(resp => {
				dispatch([
					{type: 'SEND_TEXT_CHANGE', payload: 'Enviar'}
				])
				if(resp.error){
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>'}
					])
				} else {
					alert('Arquivo enviado com sucesso')
					dispatch([
						{type: 'UPLOADS_FETCH', payload: resp.data.uploads}
					])
				}
			})
			.catch(e => {
				dispatch([
					{ type: 'LOADING', payload: false }
				])
			})
	}
}

export function deletar(file, token, aula) {
	let formData = new FormData()

	formData.append('id', file)
	formData.append('aula', aula)

	const config = {
		headers: { 
			Authorization: `Bearer ${token}`
		}
	}

	return dispatch => {
		dispatch([
			{ type: 'LOADING_MEDIA', payload: true }
		])

		axios.post(`${process.env.REACT_APP_URL}file/delete`, formData, config)
			.then(resp => {
				dispatch([
					{ type: 'LOADING_MEDIA', payload: false }
				])

				if(resp.error){
					alert('Erro ao excluir arquivo')
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>'}
					])
				} else {
					alert('Arquivo excluído com sucesso')
					dispatch([
						{type: 'UPLOADS_FETCH', payload: resp.data.uploads}
					])
				}
			})
			.catch(e => {
				dispatch([
					{ type: 'LOADING_MEDIA', payload: false }
				])
			})
	}
}

export function openModal(modal) {
	return dispatch => {
		dispatch([
			{ type: 'OPEN_MODAL', payload: modal }
		])
	}
}

export function getquestion(aula, quiz, token) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		dispatch([
			{type: 'USER_SET_ERROR', payload: ''},
			{ type: 'LOAD_QUESTION', payload: true }
		])

		axios.get(`${process.env.REACT_APP_URL}question/${aula}/${quiz}`, config)
			.then(resp => {
				if(resp.status == 200){
					if(resp.data.success){
						dispatch([
							{type: 'QUESTION_FETCH', payload: resp.data.question},
							{ type: 'LOAD_QUESTION', payload: false }
						])
					} else {
						dispatch([
							{type: 'LOAD_QUESTION', payload: false},
							{type: 'QUESTION_FETCH', payload: []},
							{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.error+'</div>'}	
						])
					}
				}
			})
	}	
}

export function answer(aula, quiz, question, alternativa, token) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	let formData = new FormData()

	formData.append('aula_id', aula)
	formData.append('quizz_id', quiz)
	formData.append('question_id', question)
	formData.append('alternativa_id', alternativa)

	return dispatch => {
		dispatch([
			{type: 'USER_SET_ERROR', payload: ''},
			{ type: 'LOAD_QUESTION', payload: true }
		])

		axios.post(`${process.env.REACT_APP_URL}question/answer`, formData, config)
			.then(resp => {
				if(resp.data.error){
					dispatch([
						{type: 'LOAD_QUESTION', payload: false},
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.error+'</div>'},
						{type: 'QUESTION_FETCH', payload: []}
					])
				} else {
					dispatch([
						{ type: 'LOAD_QUESTION', payload: false },
						{type: 'QUESTION_FETCH', payload: resp.data.question},
						{type: 'USER_SET_ERROR', payload: ''}
					])
				}
			})
			.catch(e => {
				dispatch([
					{ type: 'LOAD_QUESTION', payload: false },
					{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro no processamento de dados</div>'}
				])
			})
	}	
}