import React, {Component} from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import PrivateRoute from './helpers/PrivateRoute';

import {login as Login, logout as Logout} from './templates/auth/login'
import {forgot as Forgot} from './templates/auth/resetpwd'
import Home from './templates/main/main'
import Objetos from './templates/main/objetos'
import Trilhas from './templates/main/aulas'
import Autores from './templates/main/autores'
import Materias from './templates/main/autoresmaterias'
import Profile from './templates/main/profile'
import Aula from './templates/content/aula'
import Tests from './templates/main/tests'
import Test from './templates/content/test'
import Calling from './templates/content/calling'
import BbCalling from './templates/content/callingbb'

class Routes extends Component {
	render(){
		return(
			<Switch>
				<Route exact path="/login">
					<Login />
				</Route>
				<Route exact path="/esqueci-minha-senha">
					<Forgot />
				</Route>
				<PrivateRoute exact path="/logout" authed={this.props.userData} component={Logout} />
				<PrivateRoute exact path="/" component={Autores} authed={this.props.userData} />
				<PrivateRoute exact path="/meus-dados" component={Profile} authed={this.props.userData} />
				<PrivateRoute exact path="/objetos-de-aprendizagem/" component={Objetos} authed={this.props.userData} />
				<PrivateRoute exact path="/trilhas-de-aprendizagem/" component={Autores} authed={this.props.userData} />
				<PrivateRoute exact path="/trilhas-de-aprendizagem/autor/:autor" component={Materias} authed={this.props.userData} />
				<PrivateRoute exact path="/trilhas-de-aprendizagem/autor/:autor/materia/:materia" component={Trilhas} authed={this.props.userData} />
				<PrivateRoute exact path="/trilhas-de-aprendizagem/:id" component={Aula} authed={this.props.userData} />
				<PrivateRoute exact path="/avaliacoes/" component={Tests} authed={this.props.userData} />
				<PrivateRoute exact path="/avaliacoes/:id" component={Test} authed={this.props.userData} />
				<PrivateRoute exact path="/avaliacoes/:id/segunda-chamada/:sc" component={Test} authed={this.props.userData} calling={true} />
				<PrivateRoute exact path="/chamadas" component={Calling} authed={this.props.userData} />
				<PrivateRoute exact path="/chamadas/:id" component={Calling} authed={this.props.userData} />
				<PrivateRoute exact path="/bb-chamadas" component={BbCalling} authed={this.props.userData} />
				<PrivateRoute exact path="/bb-chamadas/:id" component={BbCalling} authed={this.props.userData} />
			</Switch>
		)
	}
}

const mapStateToProps = state => ({ userData: state.profile.userData });
export default connect(mapStateToProps)(Routes);