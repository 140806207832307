import React from 'react';
import {BrowserRouter} from 'react-router-dom'
import UpdateHandler from './templates/common/updatehandler';

import './assets/css/icons.css'
import './assets/css/metismenu.min.css'
import './assets/css/style.css'
import './assets/css/custom.css'

import Routes from './Routes'

function App() {
  return (
    <>
    <UpdateHandler />
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
    </>
  );
}

export default App;