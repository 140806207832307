import axios from 'axios'

export function infos() {
	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}infos`)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{type: 'STATICAL_CONTENT_FETCH', payload: resp.data.infos}
					])
				}
			})
	}
}

export function logout() {
	return dispatch => {
		dispatch([
			{ type: 'USER_TOKEN_VALIDATED', payload: false },
			{ type: 'CLIENTE_LOGOUT', payload: false }
		])
	}
}

export function signin(values) {
	let formData = new FormData()
	formData.append('matricula', values.login)
	formData.append('password', values.password)
	formData.append('remember', values.remember)

	return dispatch => {
		dispatch([
			{type: 'USER_SET_ERROR', payload: '<div class="form-loader alert alert-secondary mb-1"><i class="fas fa-spinner fa-spin"></i></div>'}
		])

		axios.post(`${process.env.REACT_APP_URL}auth`, formData)
			.then(resp => {
				if(resp.error){
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>'}
					])
				} else {
					dispatch([
						{type: 'SET_CLIENTE', payload: resp.data.cliente},
						{type: 'USER_FETCH_TOKEN', payload: resp.data.access_token},
						{type: 'USER_FETCH_MENU', payload: resp.data.menu},
						{type: 'USER_FETCHED', payload: resp.data.user},
						{type: 'USER_FETCH_VERSION', payload: resp.data.version.versao}
					])
				}
			})
			.catch(e => {
				dispatch([
					{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+ e.response.data.message +'</div>'}
				])
			})
	}
}

export function resetpwd(values) {
	let formData = new FormData()
	formData.append('matricula', values.login)

	return dispatch => {
		dispatch([
			{type: 'USER_SET_ERROR', payload: '<div class="form-loader alert alert-secondary mb-1"><i class="fas fa-spinner fa-spin"></i></div>'}
		])

		axios.post(`${process.env.REACT_APP_URL}reset-pwd`, formData)
			.then(resp => {
				if(resp.error){
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>'}
					])
				} else {
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-success alert-success-shadow b-round text-center mt-2">'+resp.data.message+'</div>'}
					])
				}
			})
			.catch(e => {
				dispatch([
					{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro no processamento de dados</div>'}
				])
			})
	}
}

export function changepwd(values, token) {
	const config = {
		headers: { 
			Authorization: `Bearer ${token}`
		}
	}

	let formData = new FormData()
	formData.append('password', values.password)
	formData.append('password_confirmation', values.passwordconfirmation)

	return dispatch => {
		dispatch([
			{type: 'USER_SET_ERROR', payload: '<div class="form-loader alert alert-secondary mb-1"><i class="fas fa-spinner fa-spin"></i></div>'}
		])

		axios.post(`${process.env.REACT_APP_URL}change-pwd`, formData, config)
			.then(resp => {
				if(resp.errors){
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.errors.password[0]+'</div>'}
					])
				} else {
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-success alert-success-shadow b-round text-center mt-2">'+resp.data.message+'</div>'}
					])
				}
			})
			.catch(e => {
				if(e.response.data.errors){
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+e.response.data.errors.password[0]+'</div>'}
					])
				} else {
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro no processamento de dados</div>'}
					])
				}
			})
	}
}

export function update_profile(avatar, cliente, token) {
	if(avatar !== undefined){

		const config = {
			headers: { 
				Authorization: `Bearer ${token}`,
				"Content-Type": "multipart/form-data"
			}
		}

		let formData = new FormData()
		formData.append('avatar', avatar, 'avatar')
		formData.append('cliente', cliente)

		return dispatch => {
			axios.post(`${process.env.REACT_APP_URL}profile`, formData, config)
				.then(resp => {
					if(resp.error){
						dispatch([
							{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>'},
							{type: 'LOADING', payload: false}
						])
					} else {
						dispatch([
							{type: 'USER_FETCHED', payload: resp.data.user},
							{type: 'LOADING', payload: false}
						])
					}
				})
				.catch(e => {
					dispatch([
						{type: 'USER_SET_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro no processamento de dados</div>'}
					])
				})
		}
	}	
}