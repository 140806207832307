import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Footer from '../common/footer'
import Menu from '../common/menu'

import { getautores } from '../../helpers/actions/content'

function aulas(props){
	const breadcrumb = [props.clienteData.nome, 'Trilhas de Aprendizagem', 'Lista de Trilhas de Aprendizagem']

	const {getautores} = props
	setTimeout(function(){
		if(props.contentData.length == 0){
			getautores(props.userToken)
		}
	}, 1500)

	const handleRender = (prop, other) => {
		console.log(prop)
	}

	const renderAulas = (conteudos) => {
		if(conteudos[0].length > 0){
			return (
				<>
					{conteudos[0].map((conteudo, index) => {
						return (
							<div className="col-lg-4" key={index}>
								<a href={`/trilhas-de-aprendizagem/autor/${conteudo.autor}`}>
									<div className="card profile-card">
										<div style={{ position: "relative", height: "110px", overflow: "hidden" }}>
											<img src={conteudo.background} style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }} />
										</div>
								        <div className="card-body pb-0 profile-card-user" style={{ position: "relative", zIndex: "2" }}>
								        	<div className="text-center">
								        		<img src={conteudo.avatar} className="rounded-circle thumb-xl img-thumbnail mb-2" />
								        		<p className="mb-0 font-12 text-muted">{conteudo.author}</p>
								            </div>
								        </div>
								        <div className="card-body socials-data pb-0 px-0">
								        	<div className="row text-center">
								            	<div className="col-12 py-2 border-top">
								                	<span className="font-14 btn btn-primary btn-sm">Ver Trilhas</span>
								                </div>
								            </div>
								        </div>
								        <div className="pb-0 px-0">
								        	<div className="row text-center mx-0">
								        		<div className="col-4 text-center py-2 border">
								        			<small>
								        				<p className="mb-0"><strong>Trilhas para Fazer</strong></p>
								        				<p className="mb-0">{conteudo.fazer}</p>
								        			</small>
								        		</div>
								        		<div className="col-4 text-center py-2 border mr-0">
								        			<small>
									        			<p className="mb-0"><strong>Trilhas Feitas</strong></p>
									        			<p className="mb-0">{conteudo.feitas}</p>
									        		</small>
								        		</div>
								        		<div className="col-4 text-center py-2 ml-0 border">
								        			<small>
								        				<p className="mb-0"><strong>Trilhas Expiradas</strong></p>
								        				<p className="mb-0">{conteudo.expiradas}</p>
								        			</small>
								        		</div>
								        	</div>
								        </div>
								    </div>
								</a>
							</div>
						)
					})}
				</>
			)
		} else {
			return (
				<div className="col-12 mt-3">
					<p className="text-center">Não há dados para exibir</p>
				</div>
			)
		}
	}

	return(
		<>
			<Header titulo="Trilhas de Aprendizagem" icone="fas fa-book" breadcrumb={breadcrumb} />

			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    {(props.contentData.length > 0) ? (
	                    	<div className="row">
	                    		{renderAulas(props.contentData)}
	                    	</div>
	                    ) : (
	                    	<>
	                    	<div className="loader">Loading...</div>
	                    	</>
	                    )}
	                </div>

	                <Footer cliente={props.clienteData.nome} sistema={props.clienteData.sistema} />
	            </div>
	        </div>
		</>
	)
}

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	contentData: state.content.aulas
})

const mapDispatchToProps = dispatch => bindActionCreators({
	getautores
}, dispatch)

const conAutores = connect(mapStateToProps, mapDispatchToProps)(aulas)
export default conAutores