import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

function HandleUpdates(props){
    const {logout} = props
    const [version, setVersion] = useState(null)
    
    const localVersion = localStorage.getItem('version') || null;
    
    useEffect(() => {
        async function getVersion(){
            axios.get(`${process.env.REACT_APP_URL}app/version`)
			.then(resp => {
				if(resp.status == 200){
                    setVersion(resp.data.versao.versao);
				}
			})
        }
        getVersion();
    }, [])

    useEffect(() => {
        if(version){
            if(version !== localVersion){
                logout();
                alert('O aplicativo atualizou. Por favor, faça o login novamente')
            }
        }
    }, [version])

    return null
}

const mapStateToProps = (state) => ({
	
})
const mapDispatchToProps = dispatch => bindActionCreators({
	logout: () => dispatch([
        { type: 'USER_TOKEN_VALIDATED', payload: false },
        { type: 'CLIENTE_LOGOUT', payload: false }
    ])
}, dispatch)
const conHandler = connect(mapStateToProps,mapDispatchToProps)(HandleUpdates)

export default conHandler