import React, {useState, useEffect} from 'react'

import { reduxForm, Field, reset } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getmeetings } from '../../helpers/actions/meetings'

function RenderProximas(meetings){
	if(meetings.length === 0){
		return (
			<tr>
				<td colspan="5" className="text-center">Não existem reuniões para mostrar</td>
			</tr>
		)
	} else {
		return (
			<>
			{meetings.map((meeting,index) => {
				return (
					<tr key={index}>
						<td>{meeting.titulo}</td>
						<td>{meeting.author}</td>
						<td>{meeting.inicio}</td>
						<td>{meeting.fim}</td>
						<td><a href={`/chamadas/${meeting.id}`} className="btn btn-primary btn-sm">Participar</a></td>
					</tr>
				)
			})}
			</>
		)
	}
}

function RenderAnteriores(meetings){
	if(meetings.length === 0){
		return (
			<tr>
				<td colspan="4" className="text-center">Não existem reuniões para mostrar</td>
			</tr>
		)
	} else {
		return (
			<>
			{meetings.map((meeting,index) => {
				return (
					<tr key={index}>
						<td>{meeting.titulo}</td>
						<td>{meeting.author}</td>
						<td>{meeting.inicio}</td>
						<td>{meeting.fim}</td>
					</tr>
				)
			})}
			</>
		)
	}
}

function CallingList(props){
	const {getmeetings} = props
	useEffect(() => {
		getmeetings(props.userToken)
	}, [])

	return(
		<>
		{props.meetingsData.length === undefined ? (
			<>
			<div className="col-12">
				<div className="card">
					<div className="card-body">
						<h4 className="mt-0 mb-2 header-title">Próximas Reuniões</h4>
						<div className="table-responsive">
							<table className="table mb-0 table-centered">
								<thead>
									<tr>
										<th>Título</th>
										<th>Criada Por</th>
										<th>Início</th>
										<th>Fim</th>
										<th></th>
									</tr>
								</thead>

								<tbody>
								{RenderProximas(props.meetingsData.proximos)}
								</tbody>
							</table>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<h4 className="mt-0 mb-2 header-title">Reuniões Anteriores</h4>
						<div className="table-responsive">
							<table className="table mb-0 table-centered">
								<thead>
									<tr>
										<th>Título</th>
										<th>Criada Por</th>
										<th>Início</th>
										<th>Fim</th>
									</tr>
								</thead>

								<tbody>
								{RenderAnteriores(props.meetingsData.expirados)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			</>
		) : (
			<div className="row">
				<div className="col-6 offset-md-3">
					<div className="loader">Loading...</div>
				</div>
			</div>
		)}
		</>
	)
}

const mapStateToProps = (state, ownProps) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	meetingsData: state.meetings.meetings
})

const mapDispatchToProps = dispatch => bindActionCreators({
	getmeetings
}, dispatch)
const conCallingL = connect(mapStateToProps, mapDispatchToProps)(CallingList)
export default conCallingL