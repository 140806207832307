const infosKey = 'key_infos'
const INITIAL_STATE = {
	content: [],
	infos: (localStorage.getItem(infosKey) !== 'undefined') ? JSON.parse(localStorage.getItem(infosKey)) : [],
	loading: false,
	lineClass: ''
}

export default (state = INITIAL_STATE, action) => {
	switch(action.type){
		case 'STATICAL_CONTENT_FETCH':
			return {
				...state,
				infos: action.payload
			}
			break;
		case 'INITIAL_CONTENT_FETCH':
			return {
				...state, 
				content: action.payload
			}
			break;
		case 'LOADING':
			return {
				...state,
				loading: action.payload
			}
			break;
		case 'MENU_OPEN':
			return {
				...state,
				lineClass: action.payload
			}
			break;
		default:
			return state
	}
}