import axios from 'axios'

export function gettests(token){
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}tests`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{ type: 'TESTS_FETCH', payload: resp.data.tests }
					])
				}
			})
	}
}

export function gettest(token, test) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}test/${test}`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{ type: 'SET_TIME', payload: resp.data.minutos },
						{ type: 'TEST_FETCH', payload: resp.data.test },
						{ type: 'RESULT_FETCH', payload: resp.data.test.resultado }
					])
				}
			})
	}
}

export function getquestionschedule(token, id, type) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		dispatch([
			{ type: 'LOAD_TEST_QUESTION', payload: true },
			{ type: 'ANSWER_TEST_ERROR', payload: '' }
		])

		axios.get(`${process.env.REACT_APP_URL}test/questions/${type}/${id}`, config)
			.then(resp => {
				if(resp.error){
					dispatch([
						{ type: 'LOAD_TEST_QUESTION', payload: false },
						{ type: 'SET_RESPONDIDAS', payload: []},
						{ type: 'QUESTIONS_TEST_FETCH', payload: ''},
						{ type: 'SET_RESPOSTA', payload: 0 },
						{ type: 'SET_TIPO', payload: ''},
						{ type: 'QUESTION_TEST_FETCH', payload: [] },
						{ type: 'QUESTIONS_TEST_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>' },
						{ type: 'ACTIVATE_TIMER', payload: 0 }
					])
				} else {
					dispatch([
						{ type: 'LOAD_TEST_QUESTION', payload: false },
						{ type: 'SET_RESPONDIDAS', payload: resp.data.respondidas},
						{ type: 'QUESTIONS_TEST_ERROR', payload: '' },
						{ type: 'SET_ULTIMA', payload: resp.data.questions.ultima },
						{ type: 'QUESTIONS_TEST_FETCH', payload: resp.data.questions },
						{ type: 'SET_RESPOSTA', payload: resp.data.resposta },
						{ type: 'SET_TIPO', payload: resp.data.tipo },
						{ type: 'QUESTION_TEST_FETCH', payload: resp.data.question },
						{ type: 'ACTIVATE_TIMER', payload: 1000 }
					])
				}
			})
			.catch(e => {
				dispatch([
					{ type: 'LOAD_TEST_QUESTION', payload: false },
					{ type: 'QUESTIONS_TEST_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro ao processar dados</div>' },
					{ type: 'SET_RESPONDIDAS', payload: []},
					{ type: 'QUESTIONS_TEST_FETCH', payload: ''},
					{ type: 'SET_RESPOSTA', payload: 0 },
					{ type: 'SET_TIPO', payload: ''},
					{ type: 'QUESTION_TEST_FETCH', payload: [] },
					{ type: 'ACTIVATE_TIMER', payload: 0 }
				])
			})
	}
}

export function getquestion(type, typeid, question, token, index, timer) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	return dispatch => {
		dispatch([
			{ type: 'SET_ULTIMA', payload: index },
			{ type: 'QUESTION_TEST_ERROR', payload: '' },
			{ type: 'LOAD_TEST_QUESTION_SINGLE', payload: true },
			{ type: 'ANSWER_TEST_ERROR', payload: '' }
		])

		axios.get(`${process.env.REACT_APP_URL}test/questions/${type}/${typeid}/${question.id}?timer=${timer}&tipo=${question.tipo}`, config)
			.then(resp => {
				if(resp.status == 200){
					if(resp.data.success){
						dispatch([
							{ type: 'QUESTION_TEST_FETCH', payload: resp.data.question },
							{ type: 'LOAD_TEST_QUESTION_SINGLE', payload: false },
							{ type: 'SET_RESPOSTA', payload: resp.data.resposta },
							{ type: 'SET_TIPO', payload: resp.data.tipo },
							{ type: 'QUESTION_TEST_ERROR', payload: false },
							{ type: 'ACTIVATE_TIMER', payload: 1000 }
						])
					} else {
						dispatch([
							{ type: 'LOAD_TEST_QUESTION_SINGLE', payload: false },
							{ type: 'SET_RESPOSTA', payload: 0 },
							{ type: 'SET_TIPO', payload: ''},
							{ type: 'QUESTION_TEST_FETCH', payload: [] },
							{ type: 'QUESTION_TEST_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro ao processar dados</div>' },
							{ type: 'ACTIVATE_TIMER', payload: 0 }
						])
					}
				} else {
					dispatch([
						{ type: 'LOAD_TEST_QUESTION_SINGLE', payload: false },
						{ type: 'SET_RESPOSTA', payload: 0 },
						{ type: 'SET_TIPO', payload: ''},
						{ type: 'QUESTION_TEST_FETCH', payload: [] },
						{ type: 'QUESTION_TEST_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro ao processar dados</div>' },
						{ type: 'ACTIVATE_TIMER', payload: 0 }
					])
				}
			}).catch(e => {
				dispatch([
					{ type: 'LOAD_TEST_QUESTION_SINGLE', payload: false },
					{ type: 'SET_RESPOSTA', payload: 0 },
					{ type: 'SET_TIPO', payload: ''},
					{ type: 'QUESTION_TEST_FETCH', payload: [] },
					{ type: 'QUESTION_TEST_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro ao processar dados</div>' },
					{ type: 'ACTIVATE_TIMER', payload: 0 }
				])
			})
	}	
}

export function answertest(questions, index, schedule, calling, question, alternativa, token, timer, tipo) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	let formData = new FormData()

	formData.append('tests_schedule_id', schedule)
	formData.append('tests_calling_id', calling)
	formData.append('question_id', question)
	formData.append('alternativa_id', alternativa)
	formData.append('questions', questions)
	formData.append('ultima', index)
	formData.append('timer', timer)
	formData.append('tipo', tipo)

	return dispatch => {
		dispatch([
			{ type: 'ANSWER_TEST_ERROR', payload: '' },
			{ type: 'SEND_TEST_TEXT_CHANGE', payload: '<i class="fas fa-spinner fa-spin"></i>' }
		])

		axios.post(`${process.env.REACT_APP_URL}test/question/answer`, formData, config)
			.then(resp => {
				if(resp.data.error){
					dispatch([
						{ type: 'SEND_TEST_TEXT_CHANGE', payload: 'Responder' },
						{ type: 'ANSWER_TEST_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>' },
						{ type: 'ACTIVATE_TIMER', payload: 0 }
					])
				} else {
					dispatch([
						{ type: 'SEND_TEST_TEXT_CHANGE', payload: 'Responder' },
						{ type: 'SET_ULTIMA', payload: parseInt(resp.data.ultima) },
						{ type: 'SET_RESPOSTA', payload: resp.data.resposta },
						{ type: 'SET_TIPO', payload: resp.data.tipo },
						{ type: 'SET_RESPONDIDAS', payload: resp.data.respondidas},
						{ type: 'QUESTION_TEST_FETCH', payload: resp.data.question },
						{ type: 'ANSWER_TEST_ERROR', payload: '' },
						{ type: 'ACTIVATE_TIMER', payload: 1000 }
					])
				}
			})
			.catch(e => {
				dispatch([
					{ type: 'SEND_TEST_TEXT_CHANGE', payload: 'Responder' },
					{ type: 'ANSWER_TEST_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro no processamento de dados</div>' },
					{ type: 'ACTIVATE_TIMER', payload: 0 }
				])
			})
	}
}

export function finishtest(questions, schedule, calling, token, timer, byTime = 0) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	let formData = new FormData()

	formData.append('tests_schedule_id', schedule)
	formData.append('tests_calling_id', calling)
	formData.append('questions', questions)
	formData.append('timer', timer)
	formData.append('byTime', byTime)

	return dispatch => {
		dispatch([
			{ type: 'ANSWER_TEST_ERROR', payload: '' },
			{ type: 'FINISH_TEST_TEXT_CHANGE', payload: '<i class="fas fa-spinner fa-spin"></i>' },
			{ type: 'ACTIVATE_TIMER', payload: 0 }
		])

		axios.post(`${process.env.REACT_APP_URL}test/finish`, formData, config)
			.then(resp => {
				if(resp.data.error){
					dispatch([
						{ type: 'FINISH_TEST_TEXT_CHANGE', payload: '<i class="fas fa-flag-checkered mr-2"></i>Finalizar Avaliação' },
						{ type: 'ANSWER_TEST_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">'+resp.data.message+'</div>' },
						{ type: 'ACTIVATE_TIMER', payload: 1000 }
					])
				} else {
					dispatch([
						{ type: 'ACTIVATE_TIMER', payload: 0 },
						{ type: 'SET_TIMER', payload: 0 },
						{ type: 'SET_TIME', payload: 0 },
						{ type: 'QUESTIONS_TEST_FETCH', payload: resp.data.questions },
						{ type: 'RESULT_FETCH', payload: resp.data.questions.resultado },
						{ type: 'QUESTION_TEST_FETCH', payload: [] }
					])
				}
			})
			.catch(e => {
				dispatch([
					{ type: 'FINISH_TEST_TEXT_CHANGE', payload: '<i class="fas fa-flag-checkered mr-2"></i>Finalizar Avaliação' },
					{ type: 'ANSWER_TEST_ERROR', payload: '<div class="alert alert-outline-danger alert-danger-shadow b-round text-center mt-2">Erro no processamento de dados</div>' },
					{ type: 'ACTIVATE_TIMER', payload: 1000 }
				])
			})
	}	
}

export function updatetimetest(type, id, time, token) {
	const config = {
		headers: { Authorization: `Bearer ${token}` }
	}

	let formData = new FormData()
	formData.append('time', time)

	axios.post(`${process.env.REACT_APP_URL}test/questions/${type}/${id}/time`, formData, config)
}

export function timeupdate(timer){
	return dispatch => {
		dispatch([
			{ type: 'SET_TIMER', payload: timer },
			{ type: 'SET_TIME', payload: timer }
		])
	}
}

export function stopTimer(){
	return dispatch => {
		dispatch([
			{ type: 'ACTIVATE_TIMER', payload: 0 }
		])
	}
}