const INITIAL_STATE = {
	aula: [],
	conteudo: [],
	aulas: [],
	aula_conteudo: [],
	comments: [],
	uploads: [],
	modalShow: false,
	question: [],
	loadquestion: true,
	sendtext: 'Enviar',
	loadmedia: false
}

export default (state = INITIAL_STATE, action) => {
	switch(action.type){
		case 'AULA_FETCH':
			return {
				...state, 
				aula: action.payload
			}
			break;
		case 'AULAS_FETCH':
			return {
				...state, 
				aulas: action.payload
			}
			break;
		case 'CONTEUDO_FETCH':
			return {
				...state, 
				conteudo: action.payload
			}
			break;
		case 'AULA_CONTEUDO_FETCH':
			return {
				...state, 
				aula_conteudo: action.payload
			}
			break;
		case 'COMMENTS_FETCH':
			return {
				...state, 
				comments: action.payload
			}
			break;
		case 'UPLOADS_FETCH':
			return {
				...state, 
				uploads: action.payload
			}
			break;
		case 'OPEN_MODAL':
			return {
				...state,
				modalShow: action.payload
			}
			break;
		case 'LOAD_QUESTION':
			return {
				...state,
				loadquestion: action.payload
			}
			break;
		case 'LOADING_MEDIA':
			return {
				...state,
				loadmedia: action.payload
			}
			break;
		case 'QUESTION_FETCH':
			return {
				...state,
				question: action.payload
			}
			break;
		case 'SEND_TEXT_CHANGE':
			return {
				...state,
				sendtext: action.payload
			}
		default:
			return state
	}
}