import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Footer from '../common/footer'
import Menu from '../common/menu'

import { gettests } from '../../helpers/actions/test'

function tests(props){
	const breadcrumb = [props.clienteData.nome, 'Avaliações', 'Visualizar Avaliação']

	const {gettests} = props
	setTimeout(function(){
		if(props.testsData.length == 0){
			gettests(props.userToken)
		}
	}, 1500)

	const RenderPendentes = (tests) => {
		if(tests.length > 0){
			return(
				<>
				{tests.map((test, index) => {
					return (
						<tr key={index}>
							<td><a href={test.link}>{test.name}</a></td>
							<td><a href={test.link}>{test.serie}</a></td>
							<td><a href={test.link}>{test.turma}</a></td>
							<td><a href={test.link}>{test.tempo_prova}</a></td>
							<td><a href={test.link}>{test.inicio}</a></td>
							<td><a href={test.link}>{test.fim}</a></td>
							<td>
								{(test.podeFazer) ? (
									<>
										<a href={test.link} className="btn btn-info btn-xs">
											<i className="fas fa-play mr-2"></i>Realizar
										</a>
									</>
								) : (
									<>
										<p>{ test.disponivelEm }</p>
									</>
								)}
							</td>
						</tr>
					)
				})}
				</>
			)
		} else {
			return(
				<tr>
					<td colspan="7">Não há avaliações para serem realizadas</td>
				</tr>
			)
		}
	}

	const RenderFeitas = (tests) => {
		if(tests.length > 0){
			return(
				<>
				{tests.map((test, index) => {
					return (
						<tr key={index}>
							<td><a href={test.link}>{test.name}</a></td>
							<td><a href={test.link}>{test.serie}</a></td>
							<td><a href={test.link}>{test.turma}</a></td>
							<td><a href={test.link}>{test.gasto}</a></td>
							<td><a href={test.link}>{test.inicio}</a></td>
							<td><a href={test.link}>{test.fim}</a></td>
							<td><a href={test.link} className="btn btn-success btn-xs"><i className="fas fa-check-circle mr-2"></i>Visualizar</a></td>
						</tr>
					)
				})}
				</>
			)
		} else {
			return(
				<tr>
					<td colspan="7">Não há avaliações para serem realizadas</td>
				</tr>
			)
		}
	}

	const renderTests = (tests) => {
		return (
			<>
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<h4 className="mt-0 mb-2 header-title">Avaliações Disponíveis</h4>
							<div className="table-responsive">
								<table className="table mb-0 table-centered">
									<thead>
										<tr>
											<th>Prova</th>
											<th>Série</th>
											<th>Turma</th>
											<th>Tempo para Conclusão</th>
											<th>Início</th>
											<th>Fim</th>
											<th></th>
										</tr>
									</thead>

									<tbody>
									{RenderPendentes(tests.pendentes)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<h4 className="mt-0 mb-2 header-title">Avaliações Realizadas</h4>
							<div className="table-responsive">
								<table className="table mb-0 table-centered">
									<thead>
										<tr>
											<th>Prova</th>
											<th>Série</th>
											<th>Turma</th>
											<th>Tempo Gasto</th>
											<th>Início</th>
											<th>Fim</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
									{RenderFeitas(tests.feitas)}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

	return(
		<>
			<Header titulo="Avaliações" icone="mdi mdi-calendar-text-outline" breadcrumb={breadcrumb} />

			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    {(props.testsData.length === undefined) ? (
	                    	<div className="row">
	                    		{renderTests(props.testsData)}
	                    	</div>
	                    ) : (
	                    	<>
	                    	<div className="loader">Loading...</div>
	                    	</>
	                    )}
	                </div>

	                <Footer cliente={props.clienteData.nome} sistema={props.clienteData.sistema} />
	            </div>
	        </div>
		</>
	)
}

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	testsData: state.test.tests
})

const mapDispatchToProps = dispatch => bindActionCreators({
	gettests
}, dispatch)

const conTests = connect(mapStateToProps, mapDispatchToProps)(tests)
export default conTests