import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Footer from '../common/footer'
import Menu from '../common/menu'

import { getconteudos } from '../../helpers/actions/content'

import ListContent from '../../helpers/listContent'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import brLocale from '@fullcalendar/core/locales/pt-br'

function objetos(props){
	const breadcrumb = [props.clienteData.nome, 'Objetos de Aprendizagem', 'Lista de Objetos de Aprendizagem']

	const {getconteudos} = props
	setTimeout(function(){
		if(props.contentData.length == 0){
			getconteudos(props.userToken)
		}
	}, 1500)

	const handleRender = (prop, other) => {
		console.log(prop)
	}

	const renderObjetos = (conteudos) => {
		if(conteudos[0].length > 0){
			return conteudos[0].map((conteudo, index) => {
				return (
					<div className="col-lg-3" key={index}>
						<a href={conteudo.link} target={conteudo.target}>
							<div className="card profile-card">
						    	<div className={`card-body ${conteudo.class} p-0`}>
						        	<div className="media p-3  align-items-center">                                                
						            	<img src={conteudo.thumbnail} alt="user" className="rounded-circle thumb-lg" />
						                <div className="media-body ml-3 align-self-center">
						                	<h5 className="mb-1 text-white">{conteudo.title}</h5>
						                    <p className="mb-0 font-12 text-white">{conteudo.author}</p>
						                </div>
						            </div>
						        </div>
						        <div className="card-body pb-0">
						        	<h6 className="text-center">{conteudo.materia}</h6>
						        	<h6 className="text-center mb-0"><small>{conteudo.categoria}</small></h6>
						        	<div style={{ height: '60px' }}>
						        		<div style={{ height: '100%', textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: conteudo.icone }} />
						        	</div>
						        </div>
						        <div className="card-body socials-data pb-0 px-0">
						        	<div className="row text-center border-top">
						            	<div className="col-12 py-2">
						                	<span className="font-14 btn btn-primary btn-sm">Acessar</span>
						                </div>
						            </div>
						        </div>
						    </div>
						</a>
					</div>
				)
			})	
		} else {
			return (
				<div className="col-12 mt-3">
					<p className="text-center">Não há dados para exibir</p>
				</div>
			)
		}
	}

	return(
		<>
			<Header titulo="Objetos de Aprendizagem" icone="mdi mdi-file-search" breadcrumb={breadcrumb} />

			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    {(props.contentData.length > 0) ? (
	                    	<div className="row">
	                    		{renderObjetos(props.contentData)}
	                    	</div>
	                    ) : (
	                    	<>
	                    	<div className="loader">Loading...</div>
	                    	</>
	                    )}
	                </div>

	                <Footer cliente={props.clienteData.nome} sistema={props.clienteData.sistema} />
	            </div>
	        </div>
		</>
	)
}

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	contentData: state.content.conteudo
})

const mapDispatchToProps = dispatch => bindActionCreators({
	getconteudos
}, dispatch)

const conObjeto = connect(mapStateToProps, mapDispatchToProps)(objetos)
export default conObjeto