const INITIAL_STATE = {
	meetings: [],
	meeting: [],
	meetingsbb: [],
	meetingbb: []
}

export default (state = INITIAL_STATE, action) => {
	switch(action.type){
		case 'MEETINGS_FETCH':
			return {
				...state, 
				meetings: action.payload
			}
			break;
		case 'MEETING_FETCH':
			return {
				...state, 
				meeting: action.payload
			}
			break;
		case 'MEETINGS_BB_FETCH':
			return {
				...state, 
				meetingsbb: action.payload
			}
			break;
		case 'MEETING_BB_FETCH':
			return {
				...state, 
				meetingbb: action.payload
			}
			break;
		default:
			return state
	}
}
