import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import cliente from './helpers/reducers/cliente'
import user from './helpers/reducers/user'
import main from './helpers/reducers/main'
import content from './helpers/reducers/content'
import test from './helpers/reducers/test'
import meetings from './helpers/reducers/meetings'

const rootReducer = combineReducers({
	form: formReducer,
	cliente: cliente,
	profile: user,
	main: main,
	content: content,
	test: test,
	meetings: meetings
})

export default rootReducer