import React, {useState, useEffect} from 'react'

import { reduxForm, Field, reset } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Footer from '../common/footer'
import Menu from '../common/menu'
import CallingList from './callinglist'
import CallingView from './callingview'

function Calling(props){
	const calling = props.match.params['id'] || null
	const breadcrumb = [props.clienteData.nome, 'Reuniões', 'Acessar Reunião']

	return(
		<div>
			<Header titulo="Reuniões" icone="mdi mdi-message-video" breadcrumb={breadcrumb} />

			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    <div className="row">
	                    	<div className="col-12">
              				{calling ? (
              					<CallingView id={calling} />
              				) : (
              					<CallingList />
              				)}
	                    	</div>
	                    </div>
	                </div>
	            </div>

	            <Footer cliente={props.clienteData.nome} sistema={props.clienteData.sistema} />
	        </div>
	    </div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente
})

const mapDispatchToProps = dispatch => bindActionCreators({
	
}, dispatch)
const conCalling = connect(mapStateToProps, mapDispatchToProps)(Calling)
export default conCalling