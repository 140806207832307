import React from 'react'

export default props => {
	return (
		<>
		<footer className="footer text-center text-sm-left">
	    	&copy; 2020 {props.cliente} <span className="text-muted d-none d-sm-inline-block float-right">Por { props.sistema }</span>
	    </footer>
		</>
	)
}