const user_key = 'edutec_user'
const user_token = 'edutec_user_token'
const user_menu = 'edutec_user_menu'

const INITIAL_STATE = {
	userData: (localStorage.getItem(user_key) !== 'undefined') ? JSON.parse(localStorage.getItem(user_key)) : [],
	validToken: false,
	token: (localStorage.getItem(user_token) !== 'undefined') ? localStorage.getItem(user_token) : '',
	errorUser: "",
	menu: (localStorage.getItem(user_menu) !== 'undefined') ? JSON.parse(localStorage.getItem(user_menu)) : []
}

export default (state = INITIAL_STATE, action) => {
	switch(action.type){
		case 'USER_TOKEN_VALIDATED':
			if(action.payload){
				return {...state, validToken: true, errorUser: ''}
			} else {
				localStorage.removeItem(user_key)
				localStorage.removeItem(user_token)
				return {...state, token: "", validToken: false, userData: null, errorUser: ''}
			}
			break;
		case 'USER_FETCH_TOKEN':
			localStorage.setItem(user_token, action.payload)
			return {...state, token: action.payload, validToken: true, errorUser: ''}
			break;
		case 'USER_FETCH_VERSION':
			localStorage.setItem('version', action.payload)
			return {...state}
			break;
		case 'USER_FETCHED':
			localStorage.setItem(user_key, JSON.stringify(action.payload))
			localStorage.setItem('api_address', process.env.REACT_APP_URL);
			return {...state, userData: action.payload, validToken: true, errorUser: ''}
			break;
		case 'USER_SET_ERROR':
			return {...state, errorUser: action.payload}
			break;
		case 'USER_FETCH_MENU':
			localStorage.setItem(user_menu, JSON.stringify(action.payload))
			return {...state, menu: action.payload}
			break;
		default:
			return state
	}
}