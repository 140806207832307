import React, {useState, useEffect, useRef} from 'react'

import { reduxForm, Field, reset } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getmeetingBB } from '../../helpers/actions/meetings'

const bbb = require('bigbluebutton-js')
let api = bbb.api(
    process.env.REACT_APP_BB_URL, 
    process.env.REACT_APP_BB_SECRET
  )
let http = bbb.http

function CallingV(props){
	const { getmeetingBB } = props
	const [isReady, setIsReady] = useState(false)

	useEffect(() => {
		getmeetingBB(props.userToken, props.id)
	}, [])

	async function join(){
		var joinUrl = (api.administration.join(props.userData.name, props.meetingData.id, props.meetingData.password, props.meetingData.createTime))
		window.open(joinUrl)
	}

	return(
		<>
		{props.meetingData.length === undefined ? (
			<div className="card">
				<div className="card-body">
		        	<div className="row">
		        		<div className="col-12 text-center">
		        			<div className="py-2">
		        				<h4>{ props.meetingData.titulo }</h4>
		        				<p><strong>Horário de Início:</strong> {props.meetingData.inicio}</p>
		        				<p><strong>Criada por:</strong> {props.meetingData.author}</p>
		        				<div className="row">
		        					<div className="col-12 col-md-6 offset-md-3">
		        						{(props.meetingData.internal_meeting_id !== "" && props.meetingData.isActive) ? (
		        							<button onClick={() => join()} type="button" className="btn btn-block btn-primary">Entrar na Reunião</button>
		        						):(
		        							<p className="text-center">Esta aula ainda não iniciou.</p>
		        						)}
		        					</div>
		        				</div>
		        			</div>
		        		</div>
		        	</div>
		        </div>
		    </div>
		) : (
			<div className="row">
				<div className="col-6 offset-md-3">
					<div className="loader">Loading...</div>
				</div>
			</div>
		)}
		</>
	)
}

const mapStateToProps = (state, ownProps) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	meetingData: state.meetings.meetingbb
})

const mapDispatchToProps = dispatch => bindActionCreators({
	getmeetingBB
}, dispatch)
const conBbView = connect(mapStateToProps, mapDispatchToProps)(CallingV)
export default conBbView