import React, {useState, useEffect} from 'react'
import Moment from 'react-moment';
import moment from 'moment';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { updatetimetest, timeupdate, stopTimer, finishtest } from '../../helpers/actions/test'

function Cronometro(props){
	const [timer, setTimer] = useState(props.timeElapsed)
	const testType = props.calling ? 'calling' : 'schedule'
	const id = props.calling ? props.match.params['id'] : props.testData.userschedule
	const start = moment().add(-timer, 's');

	const { updatetimetest, timeupdate, stopTimer, finishtest } = props

	const calculateTime = val => {
		const minutesElapsed = moment().diff(start, 'minutes')
		const secondsElapsed = moment().diff(start, 'seconds')
		
		setTimer(timer + 1)
		timeupdate(timer)

		if(minutesElapsed === props.testData.tempo_prova){
			stopTimer()
			
			alert('O tempo da avaliãção acabou. Ela será finalizada')

			const schedule_id = props.calling ? 0 : props.testData.userschedule
			const calling_id = props.calling ? props.match.params['id'] : 0

			finishtest(props.questionsData.id, schedule_id, calling_id, props.userToken, props.timer, 1)
		}
	}

	return(
		<>
			<i className="fas fa-flag-checkered mr-2"></i>
			<Moment onChange={(val) => {calculateTime(val)}} interval={props.timerActive} date={start} format="mm:ss" durationFromNow /> passados
		</>
	)
}

const mapStateToProps = (state, ownProps) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	testData: state.test.test,
	questionsData: state.test.questions,
	timeElapsed: state.test.time,
	timer: state.test.timer,
	timerActive: state.test.timerActive
})
const mapDispatchToProps = dispatch => bindActionCreators({
	updatetimetest,
	timeupdate,
	stopTimer,
	finishtest
}, dispatch)
const conCronometro = connect(mapStateToProps, mapDispatchToProps)(Cronometro)
export default conCronometro