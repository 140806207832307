const INITIAL_STATE = {
	tests: [],
	test: [],
	resultado: [],
	question: [],
	questions: [],
	sendText: 'Responder',
	finishText: '<i class="fas fa-flag-checkered mr-2"></i>Finalizar Avaliação',
	errorTest: '',
	errorTestQuestion: '',
	errorTestQuestionSingle: '',
	errorAnswer: '',
	loadquestion: false,
	loadquestionsingle: false,
	ultima: 0,
	time: 0,
	timerActive: 0,
	timer: 0,
	resposta: 0,
	tipo: "",
	respondidas: []
}

export default (state = INITIAL_STATE, action) => {
	switch(action.type){
		case 'TESTS_FETCH':
			return {
				...state, 
				tests: action.payload
			}
			break;
		case 'TEST_FETCH':
			return {
				...state, 
				errorTest: '',
				test: action.payload
			}
			break;
		case 'RESULT_FETCH':
			return {
				...state,
				errorTest: '',
				resultado: action.payload
			}
		case 'ERROR_TEST_FETCH':
			return {
				...state, 
				errorTest: action.payload,
				test: []
			}
			break;
		case 'LOAD_TEST_QUESTION':
			return {
				...state,
				loadquestion: action.payload
			}
			break;
		case 'LOAD_TEST_QUESTION_SINGLE':
			return {
				...state,
				loadquestionsingle: action.payload
			}
			break;
		case 'QUESTION_TEST_FETCH':
			return {
				...state,
				errorTestQuestion: '',
				question: action.payload
			}
			break;
		case 'SET_RESPOSTA':
			return {
				...state,
				resposta: action.payload
			}
			break;
		case 'SET_TIPO':
			return {
				...state,
				tipo: action.payload
			}
			break;
		case 'QUESTIONS_TEST_FETCH':
			return {
				...state,
				errorTestQuestion: '',
				questions: action.payload
			}
			break;
		case 'QUESTIONS_TEST_ERROR':
			return {
				...state,
				errorTestQuestion: action.payload
			}
			break;
		case 'QUESTION_TEST_ERROR':
			return {
				...state,
				errorTestQuestionSingle: action.payload
			}
			break;
		case 'ANSWER_TEST_ERROR':
			return {
				...state,
				errorAnswer: action.payload
			}
			break;
		case 'SEND_TEST_TEXT_CHANGE':
			return {
				...state,
				sendText: action.payload
			}
			break;
		case 'FINISH_TEST_TEXT_CHANGE':
			return {
				...state,
				finishText: action.payload
			}
			break;
		case 'SET_ULTIMA':
			return{
				...state,
				ultima: action.payload
			}
			break;
		case 'SET_RESPONDIDAS':
			return{
				...state,
				respondidas: action.payload
			}
			break;
		case 'SET_TIME':
			return{
				...state,
				time: action.payload
			}
			break;
		case 'ACTIVATE_TIMER':
			return{
				...state,
				timerActive: action.payload
			}
			break;
		case 'SET_TIMER':
			return{
				...state,
				timer: action.payload
			}
			break;
		default:
			return state
	}
}
