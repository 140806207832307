import React, {useState, useEffect} from 'react'

import { reduxForm, Field, reset } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Footer from '../common/footer'
import Menu from '../common/menu'

import FieldInput, { FieldTextarea } from '../../helpers/inputs.js'

import { gettest, getquestionschedule, getquestion, answertest, finishtest } from '../../helpers/actions/test'
import Cronometro from './cronometro'

function RenderQuestion(props, i){
	const [choice, setChoice] = useState(props.resposta)
	const { answertest, finishtest } = props

	setTimeout(function(){
		window.MathJax.typeset()
	}, 150)

	useEffect(() => {
		setChoice(props.resposta)
	}, [props.resposta])

	const handleChoice = e => {
		setChoice(e.target.value)
	}

	if(props.loadQuestionSingle){
		return (
			<>
				<div className="loader">Loading...</div>
			</>
		)
	} else if (props.questionData.length === undefined) {
		const handleAnswer = () => {
			const schedule_id = props.match.params['sc'] ? 0 : props.testData.userschedule
			const calling_id = props.match.params['sc'] ? props.match.params['sc'] : 0

			answertest(props.questionsData.id, props.ultima, schedule_id, calling_id, props.questionData.id, choice, props.userToken, props.timer, props.tipo)
		}

		const handleFinish = () => {
			const schedule_id = props.match.params['sc'] ? 0 : props.testData.userschedule
			const calling_id = props.match.params['sc'] ? props.match.params['sc'] : 0

			finishtest(props.questionsData.id, schedule_id, calling_id, props.userToken, props.timer)
		}

		return (
			<>
				<div className="mx-lg-4 px-lg-4">
					<div className="mb-4 text-center d-flex justify-content-center" dangerouslySetInnerHTML={{ __html:props.questionData.bnccname }} />
			        <div className="mx-xl-4 px-xl-2">
				        {props.questionData.alternativas.map((alternativa, index) => {
				        	return (
					       		<div key={index} className="custom-control custom-radio py-3 text-left">
			                       	<input 
			                      		type="radio" 
			                           	name="alternativa" 
			                           	id={`customRadio${index}`} 
			                            className="custom-control-input" 
			                            checked={choice == alternativa.id}
			                            value={alternativa.id}
			                           	onChange={handleChoice} />
			                        <label className="custom-control-label" htmlFor={`customRadio${index}`}>
			                            <div dangerouslySetInnerHTML={{ __html:alternativa.text }} />
			                        </label>
			                    </div>
			                )
				        })}
				        {(props.answerError !== '') ? (
				        	<div className="mx-lg-4 px-lg-4 my-3 text-center" dangerouslySetInnerHTML={{ __html:props.answerError }} />
				        ) : (
				        	<>
				        	</>
				        )}
				        <button onClick={handleAnswer} className="btn btn-primary btn-xl" type="button">
				        	<span dangerouslySetInnerHTML={{ __html: props.sendText }} />
				        </button>
				        
				        {((i - 1) === props.ultima) ? (
				        	<div className="mt-2">
					        	<button className="btn btn-success btn-xl" type="button" onClick={handleFinish}>
					        		<span dangerouslySetInnerHTML={{ __html: props.finishText }} />
						        </button>
						    </div>
				        ) : (
				        	<>
				        	</>
				        )}
				    </div>
				</div>
			</>
		)
	}
}

function RenderQuestions(testData, props){
	const { getquestionschedule, getquestion } = props

	if(props.loadQuestion){	
		return(
			<div className="loader">Loading...</div>
		)
	} else if (props.questionsError) {
		const testType = props.match.params['sc'] ? 'calling' : 'schedule'
		const id = props.match.params['sc'] ? props.match.params['sc'] : testData.userschedule

		return (
			<>
				<div className="mx-lg-4 px-lg-4">
					<div className="text-center">
						<div dangerouslySetInnerHTML={{ __html: props.questionsError }} />
						<div className="text-center mt-3">
							<button type="button" className="btn btn-primary btn-xl" onClick={() => getquestionschedule(props.userToken, id, testType)}>Iniciar Avaliação</button>
						</div>
					</div>
				</div>
			</>
		)
	} else if (props.questionsData.length === undefined) {
		const questoes = JSON.parse(props.questionsData.questions)
		const testType = props.match.params['sc'] ? 'calling' : 'schedule'
		const id = props.match.params['sc'] ? props.match.params['sc'] : testData.userschedule

		const handleIndex = (index, questao, props) => {
			getquestion(testType, id, questao, props.userToken, index, props.timer)
		}

		return(
			<>
				<div className="mx-lg-4 px-lg-4">
					<nav className="pagination custom-pagination text-center">
						<ul className="list-unstyled mx-auto">
						{(questoes.map((questao, index) => {
							let classe = (props.respondidas.includes(index)) ? 'bg-primary text-light' : ''
							classe += (index === props.ultima) ? ' bg-feita' : ''

							return(
								<li key={index} className="list-inline-item">
									<a onClick={() => handleIndex(index, questao, props)} className={`page-link ${classe}`}>
										{index + 1}
									</a>
								</li>
							)
						}))}
						</ul>
					</nav>
					<div className="mt-2">
						<hr />
						{RenderQuestion(props, questoes.length)}
					</div>
				</div>
			</>
		)
	} else {
		const testType = props.match.params['sc'] ? 'calling' : 'schedule'
		const id = props.match.params['sc'] ? props.match.params['sc'] : testData.userschedule

		return(
			<>
				<div className="mx-lg-4 px-lg-4">
					<div className="text-center">
						<div dangerouslySetInnerHTML={{ __html: testData.turmas }} />
					                
					    <div className="mt-4" dangerouslySetInnerHTML={{ __html: testData.description}} />
					    <hr />
					</div>
				</div>
				<div className="text-center">
					<button type="button" className="btn btn-primary" onClick={() => getquestionschedule(props.userToken, id, testType)}>Iniciar Avaliação</button>
				</div>
			</>
		)
	}
}

function RenderGabarito(gabarito){
	return(
		<>
			{(gabarito.map((questao, index) => {
				return (
					<div className="card" key={index}>
						<div className="card-body">
							<h4 className="mt-0 mb-2 header-title">
								Questão {index + 1} 
							</h4>
							<div className="mt-1" dangerouslySetInnerHTML={{ __html: questao.enunciado}} />
							<ul id="gabarito-list">
							{(questao.alternativas.map((alternativa, i) => {
								if(alternativa.correta === 1 && alternativa.minha_resposta){
									var $icon = "mdi mdi-check alert-icon"
									var $alert = "alert alert-success border-0"
								} else if (alternativa.correta === 1) {
									var $icon = "mdi mdi-check-all alert-icon"
									var $alert = "alert alert-info border-0"
								} else if (alternativa.correta === 0 && alternativa.minha_resposta) {
									var $icon = "mdi mdi-close alert-icon"
									var $alert = "alert alert-danger border-0"
								} else {
									var $icon = ""
									var $alert = "alert alert-light border-0"
								}

								return(
									<div className={`mb-2 icon-custom-alert ${$alert}`} key={i}>
										<i className={$icon}></i>
										<div className="alert-text" dangerouslySetInnerHTML={{ __html: alternativa.texto }} />
									</div>
								)
							}))}
							</ul>
						</div>
					</div>
				)
			}))}
		</>
	)
}

function RenderResult(testData, props){
	return (
		<>
			<div className="row">
				<div className="col-12 col-xl-8">
					<div className="card">
						<div className="card-body">
							<h4 className="text-center mt-0 mb-2 header-title">{testData.name}</h4>
							<hr />
							<div className="my-3">
								<div className="my-4 py-4 text-center display-4">
									Nota <span className="display-2">{props.resultado.nota}%</span>
								</div>
								<p><strong>{ testData.name }</strong></p>
								<p>{props.resultado.tipoText}</p>
								<p>Duração: <strong>{props.resultado.duracao}</strong></p>
								<p>Total de Acertos: <strong>{props.resultado.corretas.qtde}/{props.resultado.feitas} ({props.resultado.corretas.pct}%)</strong></p>
								<p>Total de Erros: <strong>{props.resultado.erradas.qtde}/{props.resultado.feitas} ({props.resultado.erradas.pct}%)</strong></p>
								<p>Não Respondidas: <strong>{props.resultado.naoFeitas.qtde}/{props.resultado.qtde} ({props.resultado.naoFeitas.pct}%)</strong></p>
								<div className="mt-3 pt-3">
									<p><strong>Intervalo de Classificação: {props.resultado.intervalo.range}</strong></p>
									<p><strong>Classificação: {props.resultado.intervalo.classificacao}</strong></p>
									<p>{props.resultado.intervalo.aproveitamento}</p>
								</div>
							</div>
						</div>
					</div>

					{(props.testData.gabarito.length > 0) ? (
						<>
						{RenderGabarito(props.testData.gabarito)}
						</>
					) : (
						<>
						</>
					)}
				</div>

				<div className="col-12 col-xl-4">
					<div className="card">
						<div className="card-body">
							<h4 className="text-center mt-0 mb-2 header-title">Legenda</h4>
							<hr />
							<div className="alert icon-custom-alert alert-info border-0" role="alert">
                            	<i className="mdi mdi-check-all alert-icon"></i>
                            	<div className="alert-text">
                            		Gabarito
                            	</div>
                            </div>

                            <div className="alert icon-custom-alert alert-success border-0" role="alert">
                            	<i className="mdi mdi-check alert-icon"></i>
                            	<div className="alert-text">
                            		Resposta Correta do Usuário
                            	</div>
                            </div>

                            <div className="alert icon-custom-alert alert-danger border-0" role="alert">
                            	<i className="mdi mdi-close alert-icon"></i>
                            	<div className="alert-text">
                            		Resposta Errada do Usuário
                            	</div>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

function RenderTest(testData, props){

	let test = testData
	return (
		<>
			<div className="card">
				<div className="card-body">
					<h4 className="text-center mt-0 mb-2 header-title">{ test.name}</h4>

					{(test.isInTime) ? (
					<>
						<div className="row mx-lg-4 px-lg-4">
							<div className="col-12 col-lg-4 mb-3 text-center">
								<i className="far fa-clock mr-2"></i> {test.tempo_prova} minutos
							</div>
							<div className="col-12 col-lg-4 mb-3 text-center">
								<Cronometro />
							</div>
							<div className="col-12 col-lg-4 mb-3 text-center">
								<i className="far fa-user mr-2"></i> {test.criador}
							</div>
							<div className="col-12">
								<hr />
							</div>
						</div>

						<div className="mt-3">
			            	<div className="text-center">
			            	{RenderQuestions(test, props)}
			            	</div>
			            </div>
			        </>
		            ) : (
		            <div className="text-center">
		            	Essa avaliação só pode ser feita de {test.hrinicio} até às {test.hrfim}
		            </div>
		            )}
	            </div>
			</div>
		</>
	)
}

function Test(props){
	const {gettest} = props
	const test = props.match.params['id']

	const breadcrumb = [props.clienteData.nome, 'Avaliações', 'Visualizar Avaliação']

	setTimeout(function(){
		if(props.testData.length == 0){
			gettest(props.userToken, test)
		}
	}, 1500)

	return(
		<div>
			<Header titulo="Avaliações" icone="mdi mdi-calendar-text-outline" breadcrumb={breadcrumb} />

			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    <div className="row">
	                    	<div className="col-12">
	                    		{(props.testData.length !== 0) ? (
	                    			<>
	                    			{(props.resultado.length === undefined) ? (
	                    				<>
	                    					{RenderResult(props.testData, props)}
	                    				</>
	                    			) : (
	                    				<>
	                    					{RenderTest(props.testData, props)}
	                    				</>
	                    			)}
	                    			</>
	                    		) : (
	                    			<>
	                    			<div className="loader">Loading...</div>
	                    			</>
	                    		)}
	                    	</div>
	                    </div>
	                </div>
	            </div>

	            <Footer cliente={props.clienteData.nome} sistema={props.clienteData.sistema} />
	        </div>
	    </div>
	)
}

const mapStateToProps = (state, ownProps) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	testData: state.test.test,
	questionData: state.test.question,
	questionsData: state.test.questions,
	loadQuestion: state.test.loadquestion,
	loadQuestionSingle: state.test.loadquestionsingle,
	questionsError: state.test.errorTestQuestion,
	questionError: state.test.errorTestQuestionSingle,
	answerError: state.test.errorAnswer,
	sendText: state.test.sendText,
	finishText: state.test.finishText,
	ultima: state.test.ultima,
	timer: state.test.timer,
	resposta: state.test.resposta,
	tipo: state.test.tipo,
	respondidas: state.test.respondidas,
	resultado: state.test.resultado
})

const mapDispatchToProps = dispatch => bindActionCreators({
	gettest,
	getquestionschedule,
	getquestion,
	answertest,
	finishtest
}, dispatch)
const conTest = connect(mapStateToProps, mapDispatchToProps)(Test)
export default conTest