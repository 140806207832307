import React, { useEffect, useState } from 'react'
import { Link, withRouter, Redirect } from "react-router-dom"
import AsyncSelect from 'react-select/async';
import axios from 'axios'
import {Helmet} from "react-helmet"

import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FieldInput, { CustomCheckbox } from '../../helpers/inputs.js'

import { signin, logout, infos } from '../../helpers/actions/login'

type State = {
  inputValue: string,
};

function Logout(props) {
	const { logout } = props

	logout()

	return (
		<Redirect to="/login" /> 
	)
}

function Login(props){
	const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);
    const [isPwd, setIsPwd] = useState(true);

	const { handleSubmit, signin, infos } = props

	useEffect(() => {
        console.log('in use')// I see in the console
	    const handler = e => {
	        console.log('in handler') // I do not see in the console
	        e.preventDefault();
	        setSupportsPWA(true);
	        setPromptInstall(e);
	    };

	    window.addEventListener("beforeinstallprompt", handler);
        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const onClick = e => {
        e.preventDefault();
        if (promptInstall) {
            promptInstall.prompt();
        }else{
            return;
        };
    };

  	
  	if(props.userData !== undefined && props.userData !== null){
		return (
			<Redirect to="/" />
		)
	} else {
		if (props.infoData){
			return (
				<div className="account-body">
					<Helmet>
		                <title>{props.infoData.site_title} - Login</title>
		                <link rel="shortcut icon" href={props.infoData.site_favicon} />
			            <link rel="apple-touch-icon" href={props.infoData.site_favicon} />
			            <link rel="apple-touch-icon" sizes="72x72" href={props.infoData.site_favicon} />
		            </Helmet>
					<div className="row vh-100">
			            <div className="col-lg-3  pr-0">
			                <div className="card mb-0 shadow-none">
			                    <div className="card-body">
			                        
			                        <div className="px-3">
			                            <div className="media">
			                            	{(props.infoData.site_logo > 0) ? (
			                            		<>
			                                	<a href="/" className="logo logo-admin"><img src="assets/images/logo-sm.png" height="55" alt="logo" className="my-3" /></a>
			                                	</>
			                                ) : (
			                                	<>
			                                	</>
			                                )}
			                                <div className="media-body ml-3 align-self-center">                                                                                                                       
			                                    <h4 className="mt-0 mb-1">{ props.infoData.site_title }</h4>
			                                    <p className="text-muted mb-0">Faça seu login para continuar.</p>
			                                </div>
			                            </div>   

			    						<div className="form-group mb-0 row">
		                                    <div className="col-12 mt-2">
		                                        <button 
		                                        	onClick={(e) => onClick(e)} 
		                                        	className="btn btn-primary btn-block waves-effect waves-light">
		                                        		Instalar Aplicativo<i className="fas fa-wifi ml-1"></i>
		                                        </button>
		                                    </div>
		                                </div>
			                            
			                            <form className="form-horizontal my-4" onSubmit={handleSubmit(value => signin(value))}>
			    							<div className="form-group" id="formLoginLogin">
			                                    <label htmlFor="username">Matrícula ou E-mail</label>
			                                    <div className="input-group mb-3">
			                                        <div className="input-group-prepend">
			                                            <span className="input-group-text" id="basic-addon1"><i className="mdi mdi-account-outline font-16"></i></span>
			                                        </div>
			                                        <Field
														name="login"
														placeholder="Digite sua matrícula ou e-mail"
														type="text"
														component={ FieldInput }
													/>
			                                    </div>                                    
			                                </div>
			    
			                                <div className="form-group">
			                                    <label htmlFor="userpassword">Senha</label>
			                                    <div className="input-group mb-3">
			                                        <div className="input-group-prepend">
			                                            <span className="input-group-text" id="basic-addon2"><i className="mdi mdi-key font-16"></i></span>
			                                        </div>
			                                        <Field
														name="password"
														placeholder="Digite sua senha"
														type={isPwd ? 'password' : 'text'}
														component={ FieldInput }
													/>
													<span id="togglePwd" onClick={() => {setIsPwd(!isPwd)}}>
			                                            <i className={isPwd ? `far fa-eye` : `far fa-eye-slash`} id="eyeIcon"></i>
			                                        </span>
			                                    </div>                                
			                                </div>
			    
			                                <div className="form-group row mt-4">
			                                    <div className="col-sm-6">
			                                        <div className="custom-control custom-checkbox">
			                                        	<Field
															name="remember"
															type="checkbox"
															className="custom-control-input"
															id="customControlInline"
															component={ CustomCheckbox }
														/>
			                                            <label className="custom-control-label" htmlFor="customControlInline">Lembrar Senha</label>
			                                        </div>
			                                    </div>
			                                    <div className="col-sm-6 text-right d-none">
			                                        <a href="/esqueci-minha-senha" className="text-muted font-13"><i className="mdi mdi-lock"></i> Esqueci a senha</a> 
			                                    </div>
			                                </div>
			    
			                                <div className="form-group mb-0 row">
			                                    <div className="col-12 mt-2">
			                                        <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Fazer login <i className="fas fa-sign-in-alt ml-1"></i></button>
			                                    </div>
			                                </div>  

			                                {props.submitting ?
			                                	<div>Aguarde</div>
			                                :
			                                	<div dangerouslySetInnerHTML={{ __html: props.errorUser }}></div>
			                                }
			                            </form>
			                        </div>
			                    </div>
			                </div>
			            </div>
			            <div className="col-lg-9 p-0 d-flex justify-content-center">
			                <div className="accountbg d-flex align-items-center" style={{ backgroundImage: 'url('+props.infoData.site_background+')' }}> 
			                    <div className="account-title text-white text-center">
			                    	{(props.infoData.site_logo > 0) ? (
			                    		<>
			                        		<img src={props.infoData.site_logo} alt="" className="thumb-sm" />
			                        	</>
			                        ) : (
			                        	<>
			                        	</>
			                        )}
			                        <h4 className="mt-3">Bem-vindo(a) ao { props.infoData.site_title }</h4>
			                        <div className="border w-25 mx-auto border-primary"></div>
			                        <h1 className="">{ props.infoData.login_titulo }</h1>
			                        <p className="font-14 mt-3">{ props.infoData.login_subtitulo }</p>
			                       
			                    </div>
			                </div>
			            </div>
			        </div>
				</div>
			)
		} else {
			infos()

			return (
				<div className="account-body">
					<div className="row vh-100">
						<div className="col-12 d-flex justify-content-center">
							<div className="d-flex align-items-center"> 
								<div className="loader">Loading...</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

Login = reduxForm({form: 'authForm'})(Login)
const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	resendToken: state.profile.resend,
	token: state.profile.validToken,
	errorUser: state.profile.errorUser,
	infoData: state.main.infos
})
const mapDispatchToProps = dispatch => bindActionCreators({
	signin,
	logout,
	infos
}, dispatch)

const conLogin = connect(mapStateToProps, mapDispatchToProps)(Login)
const conLogout = connect(mapStateToProps, mapDispatchToProps)(Logout)

export {conLogin as login}
export {conLogout as logout}