import React from 'react'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Footer from '../common/footer'
import Menu from '../common/menu'

import { content } from '../../helpers/actions/main'

import ListContent from '../../helpers/listContent'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import brLocale from '@fullcalendar/core/locales/pt-br'

function main(props){
	const breadcrumb = [props.clienteData.nome, 'Painel', 'Início']

	const {content} = props
	setTimeout(function(){
		if(props.contentData.length == 0){
			content(props.userToken)
		}
	}, 1500)

	const handleRender = (prop, other) => {
		console.log(prop)
		content(props.userToken, prop.startStr, prop.endStr, prop.view.type)
	}

	const handleClick = (event) => {
		/*
		if (event.event.extendedProps.type == 'objeto') {
	    	// Open url in new window
	       	window.open(event.event.extendedProps.link, "_blank");
	       	// Deactivate original link
	       	return false;
	    } else {
	    	window.open(event.event.extendedProps.link, "_self");
	    }
	    */
	    window.open(event.event.extendedProps.link, "_self");
	}

	return(
		<div>
			<Header titulo={`Bem-vindo, ${props.userData.name}`} icone="mdi mdi-monitor-dashboard" breadcrumb={breadcrumb} />

			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    <div className="row">
	                    	<div className="col-12">
	                    		{(props.contentData.length > 0) ? (
	                    			<div className="card">
	                    				<div className="card-body">
			                    			<FullCalendar
			                    				locale={brLocale}
			                    				headerToolbar={{
									            	left: 'title',
									            	center: 'dayGridMonth,listWeek',
									            	right: 'prev,next'
									            }}
										    	plugins={[ listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin ]}
										    	eventLimit={true}
										    	eventLimit={1}
										    	initialView="listWeek"
										    	datesSet={handleRender}
										    	events={props.contentData[0]}
										    	eventClick={handleClick}
										    />
										</div>
									</div>
	                    		) : (
	                    			<>
	                    			<div className="loader">Loading...</div>
	                    			</>
	                    		)}
	                    	</div>
	                    </div>
	                </div>

	                <Footer cliente={props.clienteData.nome} sistema={props.clienteData.sistema} />
	            </div>
	        </div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	contentData: state.main.content
})

const mapDispatchToProps = dispatch => bindActionCreators({
	content
}, dispatch)

const conMain = connect(mapStateToProps, mapDispatchToProps)(main)
export default conMain