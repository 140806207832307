import axios from 'axios'

export function content(token, dt_inicial, dt_final, view) {
	const config = {
		headers: { Authorization: `Bearer ${token}` },
		params: {
		    inicio: dt_inicial,
		    fim: dt_final,
		    view: view
		}
	}

	return dispatch => {
		axios.get(`${process.env.REACT_APP_URL}conteudo-inicial`, config)
			.then(resp => {
				if(resp.status == 200){
					dispatch([
						{type: 'INITIAL_CONTENT_FETCH', payload: resp.data.materiais}
					])
				}
			})
	}
}

export function openMenu(estado) {
	if(estado === ''){
		var newEstado = 'open'
	} else {
		var newEstado = ''
	}

	return dispatch => {
		dispatch([
			{type: 'MENU_OPEN', payload: newEstado}
		])
	}
}