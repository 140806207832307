import React, { useEffect, useState } from 'react'
import axios from 'axios'

import { connect } from 'react-redux'

function Rendermenu(props){
	const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);
    useEffect(() => {
        console.log('in use')// I see in the console
	    const handler = e => {
	        console.log('in handler') // I do not see in the console
	        e.preventDefault();
	        setSupportsPWA(true);
	        setPromptInstall(e);
	    };

	    window.addEventListener("beforeinstallprompt", handler);
        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const onClick = e => {
        e.preventDefault();
        if (promptInstall) {
            promptInstall.prompt();
        }else{
            return;
        };
    };

	return (
		<div className="navbar-custom-menu">
		    <div className="container-fluid">
		        <div id="navigation" className={props.estadoMenu}>
		            <ul className="navigation-menu list-unstyled text-center">
		            	<li>
		            		<a href="/">
		            			<i className="mdi mdi-monitor-dashboard"></i>Início
		            		</a>
		            	</li>
		            	{(props.clienteData.liberaca_objetos == 1) ? (
		            		<>
		            		<li>
			            		<a href="/objetos-de-aprendizagem">
			            			<i className="mdi mdi-book-multiple"></i>Objetos de Aprendizagem
			            		</a>
			            	</li>
		            		</>
		            	) : (
		            		<>
		            		</>
		            	)}
		            	<li>
		            		<a href="/trilhas-de-aprendizagem">
		            			<i className="fas fa-book"></i>Trilhas de Aprendizagem
		            		</a>
		            	</li>
		            	<li>
		            		<a href="/avaliacoes">
		            			<i className="mdi mdi-calendar-text-outline"></i>Avaliações
		            		</a>
		            	</li>
						{!!props.clienteData.url_app && (
						<li>
						<a href={props.clienteData.url_app}>
								<i className="fab fa-android"></i>Instalar Aplicativo Android
							</a>
						</li>
						)}
		            	<li className="d-block d-xl-none">
		            		<a href="/meus-dados">
		            			<i className="mdi mdi-account"></i>Meus Dados
		            		</a>
		            	</li>
		            	<li className="d-block d-xl-none">
		            		<a href="/logout" className="">
		            			<i className="mdi mdi-power"></i>Sair do Aplicativo
		            		</a>
		            	</li>
		            </ul>
		        </div>
		    </div>
		</div>
	)
}

const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	menu: state.profile.menu,
	estadoMenu: state.main.lineClass
})

const conMenu = connect(mapStateToProps)(Rendermenu)
export default conMenu