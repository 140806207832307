import React from 'react'
import { Link, withRouter, Redirect } from "react-router-dom"
import AsyncSelect from 'react-select/async';
import axios from 'axios'
import {Helmet} from "react-helmet"

import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import FieldInput, { CustomCheckbox } from '../../helpers/inputs.js'

import { resetpwd, infos } from '../../helpers/actions/login'

type State = {
  inputValue: string,
};

function Forgetpwd(props){
	const { handleSubmit, resetpwd, infos } = props

	if(props.userData !== undefined && props.userData !== null){
		return (
			<Redirect to="/" />
		)
	} else {
		if (props.infoData){
			return (
				<div className="account-body">
					<Helmet>
		                <title>{props.infoData.site_title} - Login</title>
		                <link rel="shortcut icon" href={props.infoData.site_favicon} />
			            <link rel="apple-touch-icon" href={props.infoData.site_favicon} />
			            <link rel="apple-touch-icon" sizes="72x72" href={props.infoData.site_favicon} />
		            </Helmet>
					<div className="row vh-100">
			            <div className="col-lg-3  pr-0">
			                <div className="card mb-0 shadow-none">
			                    <div className="card-body">
			                        
			                        <div className="px-3">
			                            <div className="media">
			                            	{(props.infoData.site_logo > 0) ? (
			                            		<>
			                                	<a href="/" className="logo logo-admin"><img src="assets/images/logo-sm.png" height="55" alt="logo" className="my-3" /></a>
			                                	</>
			                                ) : (
			                                	<>
			                                	</>
			                                )}
			                                <div className="media-body ml-3 align-self-center">                                                                                                                       
			                                    <h4 className="mt-0 mb-1">{ props.infoData.site_title }</h4>
			                                    <p className="text-muted mb-0">Cadastrar nova senha.</p>
			                                </div>
			                            </div>   
			                            
			                            <form className="form-horizontal my-4" onSubmit={handleSubmit(value => resetpwd(value))}>
			    							<div className="form-group" id="formLoginLogin">
			                                    <label htmlFor="username">Matrícula ou E-mail</label>
			                                    <div className="input-group mb-3">
			                                        <div className="input-group-prepend">
			                                            <span className="input-group-text" id="basic-addon1"><i className="mdi mdi-account-outline font-16"></i></span>
			                                        </div>
			                                        <Field
														name="login"
														placeholder="Digite sua matrícula ou e-mail"
														type="text"
														component={ FieldInput }
													/>
			                                    </div>                                    
			                                </div>
			    
			                                <div className="form-group row mt-4">
			                                    <div className="col-sm-6 offset-sm-6 text-right">
			                                        <a href="/" className="text-muted font-13"><i className="mdi mdi-lock"></i> Fazer Login</a> 
			                                    </div>
			                                </div>
			    
			                                <div className="form-group mb-0 row">
			                                    <div className="col-12 mt-2">
			                                        <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Enivar <i className="fas fa-sign-in-alt ml-1"></i></button>
			                                    </div>
			                                </div>  

			                                {props.submitting ?
			                                	<div>Aguarde</div>
			                                :
			                                	<div dangerouslySetInnerHTML={{ __html: props.errorUser }}></div>
			                                }
			                            </form>
			                        </div>
			                    </div>
			                </div>
			            </div>
			            <div className="col-lg-9 p-0 d-flex justify-content-center">
			                <div className="accountbg d-flex align-items-center" style={{ backgroundImage: 'url('+props.infoData.site_background+')' }}> 
			                    <div className="account-title text-white text-center">
			                    	{(props.infoData.site_logo > 0) ? (
			                    		<>
			                        		<img src={props.infoData.site_logo} alt="" className="thumb-sm" />
			                        	</>
			                        ) : (
			                        	<>
			                        	</>
			                        )}
			                        <h4 className="mt-3">Bem-vindo(a) ao { props.infoData.site_title }</h4>
			                        <div className="border w-25 mx-auto border-primary"></div>
			                        <h1 class="">{ props.infoData.login_titulo }</h1>
			                        <p className="font-14 mt-3">{ props.infoData.login_subtitulo }</p>
			                       
			                    </div>
			                </div>
			            </div>
			        </div>
				</div>
			)
		} else {
			infos()

			return (
				<div className="account-body">
					<div className="row vh-100">
						<div className="col-12 d-flex justify-content-center">
							<div className="d-flex align-items-center"> 
								<div className="loader">Loading...</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
	}
}

Forgetpwd = reduxForm({form: 'forgetForm'})(Forgetpwd)
const mapStateToProps = (state) => ({
	userData: state.profile.userData,
	resendToken: state.profile.resend,
	token: state.profile.validToken,
	errorUser: state.profile.errorUser,
	infoData: state.main.infos
})
const mapDispatchToProps = dispatch => bindActionCreators({
	resetpwd,
	infos
}, dispatch)

const conPwd = connect(mapStateToProps, mapDispatchToProps)(Forgetpwd)

export {conPwd as forgot}